import PropTypes from "prop-types"
import React from "react"
import Recaptcha from "react-google-recaptcha";


const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;


const Contact = ({home}) => (
    <section className="module" id="contact">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="m-title c-align">
								<h2>{home.contactTitle}</h2>
								<h6>{home.contactSubTitle}</h6>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
                            <form id="contact-form" name="contact-form" method="POST" data-netlify="true" action="/merci" data-netlify-recaptcha="true" netlify-honeypot="lastname">


                                <input type="hidden" name="form-name" value="contact-form" />
								<div className="row">

									<div className="form-group col-sm-12 lastname-field">
										<input className="form-control" type="text" name="lastname" placeholder="Lastname"/>
									</div>

									<div className="form-group col-sm-4">
										<input className="form-control" type="text" name="nom" placeholder="Nom" required=""/>
										<p className="help-block text-danger"></p>
									</div>
									<div className="form-group col-sm-4">
										<input className="form-control" type="email" name="email" placeholder="E-mail" required=""/>
										<p className="help-block text-danger"></p>
									</div>
									<div className="form-group col-sm-4">
										<input className="form-control" type="text" name="telephone" placeholder="Téléphone" required=""/>
										<p className="help-block text-danger"></p>
									</div>
									<div className="form-group col-sm-12">
										<textarea className="form-control" name="message" placeholder="Message" rows="8" required="" ></textarea>
									</div>
									<Recaptcha sitekey="6LcH6sQUAAAAADkw4gVTlOnQR24kEQOSc9vD1eV4" />
									<div className="form-group col-sm-12">
										<div className="text-center m-t-20">
											<button className="btn btn-round btn-brand" type="submit"><span>Envoyer le message</span></button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
)

Contact.propTypes = {
  home: PropTypes.object
}

Contact.defaultProps = {
 
}

export default Contact
