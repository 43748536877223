import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
	<footer className="footer">
	<div className="container">
		<div className="row align-items-center">
			<div className="col-md-6"><span className="copyright">© {new Date().getFullYear()} Hervé Cohonner</span></div>
			<div className="col-md-6">
				<ul className="social-icons">
					<li><a target="_blank" href="https://www.facebook.com/herve.cohonner/" title="Hervé Cohonner sur Facebook" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a></li>
					<li><a target="_blank" href="https://www.instagram.com/hervecohonner" title="Hervé Cohonner sur Instagram" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
					<li><a target="_blank" href="https://www.instagram.com/instabreizh56" title="InstaBreizh56 sur Instagram" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
					<li><a target="_blank" href="https://www.instagram.com/paysagedebretagne" title="Paysages de Bretagne sur Instagram" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
					<li><a target="_blank" href="https://www.instagram.com/reportageenbretagne" title="Reportage en Bretagne sur Instagram" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
				</ul>
			</div>
		</div>
	</div>
</footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
 
}

export default Footer
