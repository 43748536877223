/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {Helmet} from "react-helmet";
import Header from "./header"
import "./layout.css"
import Footer from "./footer";
import Contact from "./contact";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }

      allContentfulAccueil (limit:1) {
        edges {
          node {
            id
        contactTitle
        contactSubTitle
        metaTitle
        metaDescription
        metaKeywords
        darkMenuLabel
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Lora|Montserrat:400,500,600,700|Open+Sans:400,400i,700" rel="stylesheet"/>
      </Helmet>

      <Header siteTitle={data.site.siteMetadata.title} darkLabels={data.allContentfulAccueil.edges[0].node.darkMenuLabel}/>
      <div className="wrapper">
        {children}
        <Contact home={data.allContentfulAccueil.edges[0].node}/>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
