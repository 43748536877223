import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link } from "gatsby"
import LogoWhite from "../../assets/images/ch-blanc.svg"
import LogoBlack from "../../assets/images/signature-noir.svg"


class Header extends Component {
  
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.state = {
      navTopClass : false,
      mobileShowMenu : false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {

    if (window.pageYOffset >= 5) {
      this.setState({navTopClass : true});
    } else {
      this.setState({navTopClass : false});
    }

  }

  showMenu(event) {
    this.setState({
      mobileShowMenu: !this.state.mobileShowMenu
    });
    event.preventDefault();
  }


  render() {


    return (  <header className={`header header-center ${this.state.navTopClass?"header-shadow header-small":""} ${this.props.darkLabels?"dark-labels":""}`} id="header">
    <div className="container-fluid">
      <div className="inner-header"><Link className="inner-brand" to="/"><img src={(this.props.darkLabels || this.state.navTopClass)?LogoBlack:LogoWhite} alt="Hervé Cohonner, photographe, Lorient" width="100px"/></Link></div>
      <div className={`inner-navigation collapse ${this.state.mobileShowMenu?"show":""}`}>
        <div className="inner-nav onepage-nav">
          <ul>
            <li><Link to="/#profile"><span className="menu-item-span">A Propos</span></Link></li>
            <li><Link to="/portfolio"><span className="menu-item-span">Portfolio</span></Link></li>
            <li><a href="http://www.jingoo.com/album/postershop/index.php?id_photographe=9409"><span className="menu-item-span">La boutique</span></a></li>
            <li><Link to="/#contact"><span className="menu-item-span">Contact</span></Link></li>
          </ul>
        </div>
      </div>
      <div className="nav-toggle"><a href="#" onClick={this.showMenu} data-target=".inner-navigation"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></a></div>
    </div>
  </header>)
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
  darkLabels: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  darkLabels: true
}

export default Header
